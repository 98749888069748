@import url("../../data/styles.css");

.homepage-logo-container {
	align-items: center;
	display: flex;
	justify-content: left;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.homepage-first-area-left-side {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.homepage-first-area-right-side {
	align-items: center;
	display: flex;
	position: relative;
}

.homepage-image-container {
	height: 370px;
	width: 370px;
}

.homepage-image-wrapper {
    border-radius: 51% 49% 77% 23% / 65% 50% 50% 35%;
    overflow: hidden;
}

.homepage-image-wrapper-loading {
	background: #f0f0f0;
	border-radius: 51% 49% 77% 23% / 65% 50% 50% 35%;
	height: 100%;
	margin: 0 auto;
    overflow: hidden;
	position: relative;
	width: 100%;
}

.homepage-image-wrapper-loading::before {
	animation: shimmer 1.5s infinite;
	background: linear-gradient(to right, transparent 0%, #e0e0e0 50%, transparent 100%);
	content: '';
	display: block;
	height: 100%;
	left: -150px;
	position: absolute;
	top: 0;
	width: 100%;
}

@keyframes shimmer {
	0% {
	  transform: translateX(-150%);
	}

	100% {
	  transform: translateX(150%);
	}
}


.homepage-image {
    height: auto;
	max-width: 100%;
	position: relative;
	vertical-align: middle;
}

.image-wrap-circle {
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
    height: 104%;
    position: absolute;
    right: 4%;
    top: 2%;
    width: 102%;
    z-index: 0;
}

.homepage-tag {
	color: var(--secondary-color);
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;;
}

.homepage-socials {
	align-items: center;
	display: flex;
	justify-content: left;
	padding-top: 30px;
}

.homepage-social-icon {
	color: var(--secondary-color);
	font-size: 25px;
	padding-right: 20px;
	transition: color 0.2s ease-in-out;
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	margin-left: -30px;
	padding-top: 30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-logo-container {
		display: none;
	}

	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		order: 2;
		width: 100%;
	}

	.homepage-title {
		margin-top: 20px;
		order: 2;
		width: 100% !important;
	}

	.homepage-subtitle {
		margin-top: 10px;
		order: 3;
		text-align: justify;
		width: 100% !important;
	}

	.homepage-first-area-right-side {
		order: 1;
		width: 100%;
	}

	.homepage-image-wrapper {
		border-radius: 10%;
		margin-top: 20px;
		transform: rotate(3deg);
	}

	.homepage-image-wrapper-loading {
		border-radius: 10%;
		margin-top: 20px;
		transform: rotate(3deg);
	}

	.image-wrap-circle {
		display: none;
	}

	.homepage-image-container {
		margin-left: 10px;
		order: 1;
		padding-bottom: 50px;
		padding-top: 50px;
	}

	.homepage-after-title { 
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
