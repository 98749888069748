.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
	display: flex;
	position: fixed;
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
}

.contact-socials {
	align-items: center;
	display: flex;
	justify-content: flex-start;
}

.contact-link {
	color: var(--link-color);
}

@media (max-width: 600px) {
	.contact-subtitle {
		text-align: justify;
	}
}

.contact-form {
	padding: 2rem;
	width: 60%;
}

.form-group {
	margin-bottom: 1.5rem;
}

.form-input,
.form-textarea {
	background: var(--background-color);
	border: 1px solid var(--secondary-color);
	border-radius: 5px;
	color: var(--primary-color);
	font-size: 1rem;
	padding: 0.8rem;
	transition: border-color 0.3s ease;
	width: 100%;
}

.form-textarea {
	min-height: 100px;
	resize: vertical;
}

.form-input:focus,
.form-textarea:focus {
	border-color: var(--primary-color);
	outline: none;
}

.submit-button {
	background-color: var(--primary-color);
	border: none;
	border-radius: 5px;
	color: var(--background-color);
	cursor: pointer;
	font-size: 1rem;
	padding: 0.8rem 2rem;
	transition: background-color 0.3s ease;
}

.submit-button:hover {
	background-color: var(--secondary-color);
}

.submit-button:disabled {
	background-color: var(--secondary-color);
	cursor: not-allowed;
	opacity: 0.7;
}

.success-message {
	color: #4caf50;
	margin-top: 1rem;
	text-align: center;
}

.error-message {
	color: #f44336;
	margin-top: 1rem;
	text-align: center;
}

.alternative-contact {
	border-top: 1px solid var(--secondary-color);
	margin-top: 2rem;
	padding: 1rem;
	text-align: center;
}

.flex-container {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 600px) {
	.flex-container {
		flex-direction: column-reverse;
	}

	.contact-form {
		padding: 3rem;
		width: auto;
	}
}