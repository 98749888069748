.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
	display: flex;
	position: fixed;
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}

	.projects-subtitle {
		text-align: justify;
	}
}
