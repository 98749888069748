@import url("/src/data/styles.css");

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: #383636;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(0 0 0);
}

.page-content {
	background-color: var(--background-color);
	position: relative;
}

.page-content::before,
.page-content::after {
	background-color: var(--side-background-color);
	bottom: 0;
	box-shadow: inset rgb(0 0 0 / 16%) 0 -10px 36px 0, inset rgb(0 0 0 / 6%) 0 0 0 1px;
	content: "";
	position: absolute;
	top: 0;
	width: calc((100% - 1200px) / 2);
}

.page-content::before {
	left: 0;
	z-index: 1;
}

.page-content::after {
	right: 0;
	z-index: 1;
}

.content-wrapper {
	margin: 0 auto;
	max-width: 1000px;
	min-height: 100vh;
}

.title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 45px;
	font-weight: 700;
	width: 70%;
}

.subtitle {
	color: var(--secondary-color);
	font-size: 16px;
	line-height: 28px;
	margin-top: 25px;
	padding-top: 10px;
	width: 70%;
}

.page-footer {
	position: block;
}

.page-footer::before {
	border-top: 2px solid var(--tertiary-color);
	content: "";
	left: 0;
	position: absolute;
	right: 0;
}

@media (max-width: 1270px) {
	.page-content::before,
	.page-content::after {
		width: calc((100% - 90%) / 2);
	}

	.content-wrapper {
		max-width: 90%;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media (max-width: 1024px) {
	.page-content::before,
	.page-content::after {
		width: calc((100% - 950px) / 2);
	}

	.content-wrapper {
		max-width: 980px;
	}
}

@media (max-width: 800px) {
	.content-wrapper {
		margin: 0 auto;
		max-width: 90%;
		padding: 0 10px;
	}

	.page-content::before,
	.page-content::after {
		display: none;
	}

	.title {
		width: 100%;
	}

	.subtitle {
		width: 100%;
	}
}


.loading-wrap {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
}

.loading-text {
	color: #fbae17;
	display: inline-block;
	font-size: 18px;
	font-weight: 600;
	margin-left: 8px;
}

.bounceball {
	display: inline-block;
	height: 37px;
	position: relative;
	width: 15px;
}

.bounceball::before {
	animation: bounce 500ms alternate infinite ease;
	background-color: #fbae17;
	border-radius: 50%;
	content: '';
	display: block;
	height: 15px;
	position: absolute;
	top: 0;
	transform-origin: 50%;
	width: 15px;
}
@keyframes bounce {
	0% {
		border-radius: 60px 60px 20px 20px;
		height: 5px;
		top: 30px;
		transform: scaleX(2);
   }

	35% {
		border-radius: 50%;
		height: 15px;
		transform: scaleX(1);
   }

	100% {
		top: 0;
   }
}