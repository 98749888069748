@import url("../../../data/styles.css");

.nav-container {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 0;
}

.navbar {
	align-items: center;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	background: var(--elements-background-color);
	border-radius: 40px;
	box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	width: 380px;
}

.nav-list {
	align-items: center;
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-size: 80%;
	font-weight: bold;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	color: var(--primary-color);
	text-decoration: none;
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.switch {
	display: inline-block;
	height: 28px;
	position: fixed;
	right: 200px;
	top: 3vh;
	width: 50px;
	z-index: 999;
}

.switch input {
	height: 0;
	opacity: 0;
	width: 0;
}

.slider {
	background-color: #ccc;
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.4s;
}
  
.slider::before {
	background: white url('https://i.ibb.co/7JfqXxB/sunny.png');
	background-position: center;
	background-repeat: no-repeat;
	bottom: 4px;
	bottom: 0;
	box-shadow: 0 0 15px #2020203d;
	content: "";
	height: 30px;
	left: -2px;
	margin: auto 0;
	position: absolute;
	top: 0;
	transition: 0.4s;
	width: 30px;
}
  
input:checked + .slider {
	background-color: #333;
}

input:focus + .slider {
	box-shadow: 0 0 1px #333;
}

input:checked + .slider::before {
	background: white url('https://i.ibb.co/FxzBYR9/night.png');
	background-position: center;
	background-repeat: no-repeat;
	transform: translateX(24px);
}
  
.slider.round {
	border-radius: 34px;
}
  
.slider.round::before {
	border-radius: 50%;
}

@media (max-width: 600px) {
	.navbar {
		font-size: 80%;
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
	}

	.nav-background {
		height: 40px;
		width: 80%;
	}

	.nav-item {
		font-weight: bold;
	}

	.switch {
		right: 20px;
		top: 32vh;
	}
}
