@import url("../../../data/styles.css");

.project {
	transition: all 0.3s ease;
}

.project-container {
	box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
	height: 300px;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.project-background {
	background-position: center;
	background-size: cover;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	transition: transform 0.3s ease;
	width: 100%;
}

.project-container:hover .project-background {
	transform: scale(1.1);
}

.project-content {
	background: var(--elements-background-color);
	bottom: 0;
	height: 260px;
	left: 0;
	opacity: 0.9;
	overflow-y: scroll;
	padding: 20px;
	position: absolute;
	right: 0;
	transform: translateY(calc(100% - 80px));
	transition: transform 0.3s ease;
}

.project-content::-webkit-scrollbar {
	display: none;
}

.project-header {
	align-items: center;
	display: flex;
	gap: 20px;
}

.project-container:hover .project-content {
	transform: translateY(0);
}

.project-logo {
	width: 30px;
}

.project-logo img {
	height: 100%;
	object-fit: contain;
	width: 100%;
}

.project-title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 1em;
	padding-top: 15px;
}

.project-description {
	color: var(--secondary-color);
	margin-top: 0.5em;
}

.project-links {
	display: flex;
	gap: 15px;
	margin-top: 1em;
	opacity: 0;
	transform: translateY(10px);
	transition: all 0.3s ease;
}

.project-container:hover .project-links {
	opacity: 1;
	transform: translateY(0);
}

.project-link {
	align-items: center;
	background: #f0f0f0;
	border-radius: 5px;
	color: #333;
	display: flex;
	font-size: 0.9rem;
	gap: 5px;
	padding: 5px 10px;
	text-decoration: none;
	transition: background 0.3s ease;
}

.project-link:hover {
	color: var(--link-color);
}