.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
	display: flex;
	position: fixed;
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-basis: 300px;
	flex-direction: column;
}

.about-image-container {
	height: 370px;
	position: relative;
	width: 370px;
}

.about-image-wrapper {
	border-radius: 51% 49% 77% 23% / 65% 50% 50% 35%;
    overflow: hidden;
}

.about-image-wrapper-loading {
	background: #f0f0f0;
	border-radius: 51% 49% 77% 23% / 65% 50% 50% 35%;
	height: 100%;
	margin: 0 auto;
    overflow: hidden;
	position: relative;
	width: 100%;
}

.about-image-wrapper-loading::before {
	animation: shimmer 1.5s infinite;
	background: linear-gradient(to right, transparent 0%, #e0e0e0 50%, transparent 100%);
	content: '';
	display: block;
	height: 100%;
	left: -150px;
	position: absolute;
	top: 0;
	width: 100%;
}

@keyframes shimmer {
	0% {
	  transform: translateX(-150%);
	}

	100% {
	  transform: translateX(150%);
	}
}

.about-image {
    height: auto;
	max-width: 100%;
	position: relative;
	vertical-align: middle;
	z-index: 2;
}

.about-image-wrap-circle {
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
    height: 100%;
	left: 4%;
	position: absolute;
    width: 100%;
    z-index: 1;
}

.about-socials {
	padding-top: 50px;
}

.about-socials-mobile {
	display: none;
}

.about-after-title {
	align-items: center;
	display: flex;
}

.about-works {
	flex-basis: 600px;
	margin-right: 80px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-after-title {
		align-items:normal;
		flex-direction: column
	}

	.about-subtitle {
		padding-top: 20px !important;
		text-align: justify;
		width: 100% !important;
	}

	.about-left-side {
		align-items: center;
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-wrapper {
		border-radius: 10%;
		display: inline-block;
		margin-top: 20px;
		transform: rotate(3deg);
		width: 100%;
	}

	.about-image-wrapper-loading {
		border-radius: 10%;
		display: inline-block;
		margin-top: 20px;
		transform: rotate(3deg);
		width: 100%;
	}

	.about-image-wrap-circle {
		display: none;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		display: flex;
		padding-top: 30px;
	}

	.about-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
